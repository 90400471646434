<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <v-dialog v-model="dialog.update" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-2 text-first d-flex mb-4" style="background-color: #F05326;">
          <span class="white--text">
            Ubah Harga Paket
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <ValidationObserver ref="observer">
            <div>
              <ValidationProvider name="Harga Original" rules="required" v-slot="{ errors }">
                <v-currency-field
                  outlined
                  dense
                  id="price"
                  color="#F05326"
                  label="Harga Originial"
                  :error-messages="errors"
                  v-model="form.original"/>
              </ValidationProvider>
              <ValidationProvider name="Harga Real" rules="required" v-slot="{ errors }">
                <v-currency-field
                  outlined
                  dense
                  id="price"
                  color="#F05326"
                  label="Harga Real"
                  :error-messages="errors"
                  v-model="form.real"/>
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="closeDialog()">
            Tutup
          </v-btn>
          <v-btn
            small
            width="70"
            color="#F05326"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="save()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Price Package</div>
      <!-- <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Paket Harga . . ."
              autocomplete="off"
              v-model="searching"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              @click="showFilter = !showFilter"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize ml-5">
              <span v-if="showFilter">Batalkan</span>
              <span v-else>Filter</span>
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row> -->

      <v-expand-transition>
        <v-col v-if="showFilter" cols="12">
          <v-row class="">
            <v-col>
              <v-text-field
                v-model="filter.email"
                dense
                outlined
                placeholder="Email"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="filter.provider"
                :items="providerItems"
                dense
                outlined
                item-text="text"
                item-value="value"
                placeholder="Provider"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu.min_register"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.min_register"
                    label="Tanggal Min Register"
                    color="#F05326"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#F05326"
                  v-model="filter.min_register"
                  @input="menu.min_register = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu.max_register"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.max_register"
                    label="Tanggal Max Register"
                    color="#F05326"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#F05326"
                  v-model="filter.max_register"
                  @input="menu.max_register = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn depressed class="mr-3" color="#F05326" dark @click="fetch()">
                Search
              </v-btn>
              <v-btn depressed @click="fetch(); filter = {}">
                Reset
              </v-btn>
              <v-btn
                v-show="Object.keys(filter).length > 0"
                color="teal"
                class="white--text ml-3"
                depressed>
                <v-icon left>mdi-download</v-icon>
                <vue-excel-xlsx
                  :data="items.list"
                  :columns="columnsExcel"
                  :file-name="'Data User'"
                  :file-type="'xlsx'"
                  :sheet-name="'Data User'">
                  Export Excel
                </vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="props.item.image" style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="props.item.image">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                     <v-list-item-avatar v-else style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="require('@/assets/img/profile_picture.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </td>
                <td class="text-start white">
                  <v-btn text @click="detail(props.item)" class="text-title">
                    <div style="border-bottom: dashed 1px blue">
                      {{ props.item.title }}
                    </div>
                  </v-btn>
                </td>
                <td class="text-start white">
                  {{ props.item.term_note }}
                </td>
                <td class="text-start white">
                  {{ props.item.description }}
                </td>
                <td class="text-start white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ attrs ,on }">
                      <v-icon color="#3DDC84" size="30" class="mr-2" v-on="on" v-bind="attrs" v-show="props.item.available_android">mdi-android</v-icon>
                    </template>
                    <span>Tersedia pada Android</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ attrs ,on }">
                      <v-icon color="#424242" size="30" class="ml-2" v-on="on" v-bind="attrs" v-show="props.item.available_ios">mdi-apple</v-icon>
                    </template>
                    <span>Tersedia pada IOS</span>
                  </v-tooltip>
                </td>
                <td class="text-start white" style="flex-direction: column">
                  <div>
                    <v-chip
                      class="ma-2"
                      color="pink"
                      label
                      text-color="white">
                      <v-icon left>
                        mdi-label
                      </v-icon>
                      Original <span class="font-weight-bold ml-1">{{ props.item.price.original | price }}</span>
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                      class="ma-2"
                      color="teal"
                      label
                      text-color="white">
                      <v-icon left>
                        mdi-label
                      </v-icon>
                      Real <span class="font-weight-bold ml-1">{{ props.item.price.real | price }}</span>
                    </v-chip>
                  </div>
                </td>
                <td class="text-center white">
                  <div :class="props.item.status == 'active' ? 'teal--text font-weight-bold' : 'red--text font-weight-bold'">
                    {{ props.item.status == 'active' ? 'Aktif' : 'Tidak aktif' }}
                  </div>
                </td>
                <td class="text-center white">
                    {{ props.item.updated_at | datetime }}
                </td>
                <!-- <td class="text-center white">
                  <v-btn
                    icon
                    color="blue"
                    @click="detail(props.item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </td> -->
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import moment from 'moment'
export default {
    components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      filter: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'Image',align: 'start',value: 'image',sortable: false },
        { text: 'Judul',align: 'start',value: 'title',sortable: false },
        { text: 'Catatan',align: 'start',value: 'term_note',sortable: false },
        { text: 'Deskripsi',align: 'start',value: 'description',sortable: false },
        { text: 'Tersedia pada',align: 'start',value: 'avalilable',sortable: false },
        { text: 'Harga',align: 'start',value: 'price',sortable: false },
        { text: 'Status',align: 'center',value: 'status',sortable: false },
        { text: 'Terakhir diupdate',align: 'center',value: 'last_access',sortable: false },
        { text: '',align: 'end',value: 'action',sortable: false }
      ],
      items:{},
      data:[],
      searching:"",
      process: {
        run: false
      },
      form: {
        id: "",
        original: 0,
        real: 0
      },
      dialog: {
        update: false
      },
      menu: {
        min_register: false,
        max_register: false
      }
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal
      this.fetch()
    }, 800),
  },
  created(){
    this.fetch()
  },
  computed:{
    columnsExcel() {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Nama",
          field: "child_name",
        },
        {
          label: "Username",
          field: "username",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Jenis Kelamin",
          field: "gender",
        },
        {
          label: "Provider",
          field: "provider",
        },
        {
          label: "Alamat",
          field: "address",
        },
        {
          label: "Umur",
          field: "age",
        },
        {
          label: "Tanggal Lahir",
          field: "birthday",
        },
        {
          label: "Tempat Lahir",
          field: "birth_place",
        },
        {
          label: "Tanggal Daftar",
          field: "created_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Akses Terakhir",
          field: "last_login",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Status Berlangganan",
          field: "subscription.is_subscribed",
          dataFormat: (value) => {
            return value == true ? '✅' : '❌'
          }
        },
        {
          label: "Nama Langganan",
          field: "subscription.package.title",
        },
        {
          label: "Tanggal Berlangganan",
          field: "subscription.package.subscribe_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Tanggal Habis",
          field: "subscription.package.expired_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "License",
          field: "subsList",
          dataFormat: this.LicenseFormat,
        }
      ]
    },
    providerItems() {
      return [{ text: 'Facebook', value: 'facebook'}, { text: 'Google', value: 'google'}, { text: 'Apple', value: 'apple' }];
    },
    limitItems() {
      return [{ text: '10' }, { text: '25' }, { text: '50' }, { text: '100' }];
    },
  },
  mounted(){
  },
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true
      this.$store.state.process.run = true

      await get(`/admin/v1/subscription-package/list`,{
        params:{
          limit: this.limit,
          page: paging,
          q: this.searching
          // provider: this.filter.provider,
          // email: this.filter.email,
          // min_register: this.filterMinRegister,
          // max_register: this.filterMaxRegister
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.$store.state.process.run = false
        this.process.run = false
        this.totalData = res.data.total
      })
    },
    detail(item){
      this.form = {
        id:
          item === undefined ? "" : item.id,
        original:
          item === undefined ? "" : item.price.original,
        real:
          item === undefined ? 0 : item.price.real,
      };
      this.dialog.update = true
    },
    async closeDialog() {
      this.dialog.update = false;
      await this.$refs.observer.reset();
      this.form = {
        id: "",
        original: 0,
        real: 0
      }
    },
    async save(){
      this.process.run = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await put(`admin/v1/subscription-package/update_price/${this.form.id}`, {
          data: {
            original: this.form.original,
            real: this.form.real
          }
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.process.run = false
            this.$refs.snackbar.open("#4CAF50", `Price Package Updated Succesfully`);
            this.closeDialog()
            setTimeout(() => {
              this.fetch(1)
            }, 2000)
          }else{
            this.process.run = false
            this.$refs.snackbar.open("error", `Price Package Update Failed`);
          }
        })
      }else{
        this.process.run = false
        this.$refs.snackbar.open("error", `Price Package Update Failed`);
      }
    }

  }
}
</script>

<style>

</style>